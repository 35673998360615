import React, { useState, useRef, useEffect } from 'react';
import { Box, Paper, Typography, TextField, Button, IconButton, Container, Link } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import useAuth from '../hooks/useAuth';
import HipaaAuthModal from './HipaaAuthModal';
import { axiosInstance } from '../api/axiosConfig';

const OrinChat = () => {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [showHipaaModal, setShowHipaaModal] = useState(false);
  const [chatHistory, setChatHistory] = useState([
    { 
      text: "Welcome! Before we begin, please review and accept our HIPAA authorization ",
      sender: 'assistant',
      includeLink: true,
      feedback: null 
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [processingStarted, setProcessingStarted] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  // Helper function to add random delay
  const addMessageWithDelay = async (message, setChatHistory) => {
    // Random delay between 500ms and 2000ms
    const delay = Math.random() * 1500 + 500;
    await new Promise(resolve => setTimeout(resolve, delay));
    setChatHistory(prev => [...prev, message]);
  };

  const handleHipaaAuthorize = async (signatureData) => {
    setShowHipaaModal(false);
    
    // Add initial messages after HIPAA authorization with delays
    const initialMessages = [
      { text: "Thank you for accepting the HIPAA authorization.", sender: 'assistant' }
    ];

    // Check if demo mode is enabled
    try {
      const response = await fetch('https://chat.orin.health/config', {
        headers: {
          'Authorization': `Bearer ${user.access_token}`,
        },
      });
      const config = await response.json();
      
      if (config.ORIN_DEMO) {
        initialMessages.push({
          text: "THIS IS A DEMO account using DEMO data of a random individual. This is not your data and is for demo purposes only.",
          sender: 'assistant'
        });
      }
    } catch (error) {
      console.error('Error checking demo status:', error);
    }

    // Add processing message
    initialMessages.push({
      text: "I'm now analyzing your medical records to build a comprehensive understanding of your health history. This typically takes 3-5 minutes, as I carefully review your records to ensure accuracy. Would you like me to email you when the analysis is complete, or would you prefer to wait here? In the meantime, feel free to ask me any health-related questions.",
      sender: 'assistant',
      showEmailOption: true  // Flag to show email notification button
    });

    // Add messages one by one with delays
    for (const msg of initialMessages) {
      await addMessageWithDelay(msg, setChatHistory);
    }
    setProcessingStarted(true);

    // Initiate processing on the backend
    try {
      await fetch('https://chat.orin.health/summaries/initiate-summarization', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${user.access_token}`,
        },
      });
    } catch (error) {
      console.error('Error initiating summarization:', error);
    }
  };

  // Add WebSocket status update handling
  useEffect(() => {
    if (!user) return;

    const ws = new WebSocket(`wss://chat.orin.health/ws/${user.id}`);
    
    ws.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      
      if (data.event === 'processing_status') {
        if (data.status === 'processing_chunks') {
          await addMessageWithDelay({
            text: "Currently processing your medical records...",
            sender: 'assistant'
          }, setChatHistory);
        } else if (data.status === 'generating_summary') {
          await addMessageWithDelay({
            text: "Creating your comprehensive health summary...",
            sender: 'assistant'
          }, setChatHistory);
        }
      } else if (data.event === 'full_summary_complete' && data.success) {
        await addMessageWithDelay({
          text: "Your Health History is now available! You can access it through the 'Health History' tab in the side panel. This comprehensive summary provides an overview of your medical history, organized by time periods and categories. You can also download it as a PDF for your records.",
          sender: 'assistant'
        }, setChatHistory);
      }
    };

    return () => {
      ws.close();
    };
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    // Add user message to chat
    setChatHistory(prev => [...prev, { text: message, sender: 'user' }]);
    const userMessage = message;
    setMessage('');
    setIsLoading(true);

    try {
      const payload = {
        message: userMessage,
        is_end: false
      };

      const response = await fetch('https://chat.orin.health/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`,
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = '';
      let doneReading = false;

      while (!doneReading) {
        const { done, value } = await reader.read();
        doneReading = done;
        const chunk = decoder.decode(value || new Uint8Array(), { stream: !done });
        const lines = chunk.split('\n\n');

        for (const line of lines) {
          if (line.trim() === '') continue;
          if (line.startsWith('data: ')) {
            const data = line.replace('data: ', '');
            if (data.startsWith('Error:')) {
              throw new Error(data);
            }
            assistantMessage += data;
            setChatHistory(prev => {
              const updated = [...prev];
              if (updated[updated.length - 1]?.sender === 'assistant') {
                updated[updated.length - 1] = { text: assistantMessage, sender: 'assistant' };
              } else {
                updated.push({ text: assistantMessage, sender: 'assistant' });
              }
              return updated;
            });
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setChatHistory(prev => [...prev, { 
        text: "I apologize, but I'm having trouble connecting to the chat service. Please try again.", 
        sender: 'assistant' 
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetChat = async () => {
    try {
      await fetch('https://chat.orin.health/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`,
        },
      });
      setChatHistory([
        { 
          text: "Welcome! Before we begin, please review and accept our HIPAA authorization ",
          sender: 'assistant',
          includeLink: true,
          feedback: null 
        }
      ]);
    } catch (error) {
      console.error('Error resetting chat:', error);
    }
  };

  const handleFeedback = (index, type) => {
    setChatHistory(prev => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        feedback: type
      };
      return updated;
    });
  };

  return (
    <Box sx={{ mt: 4, height: '60vh', display: 'flex', flexDirection: 'column' }}>
      <Paper 
        elevation={3} 
        sx={{ 
          flex: 1, 
          mb: 2, 
          p: 2, 
          overflow: 'auto',
          bgcolor: '#f5f5f5'
        }}
      >
        {chatHistory.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            <Paper
              sx={{
                p: 1,
                maxWidth: '70%',
                bgcolor: msg.sender === 'user' ? 'primary.main' : 'white',
                color: msg.sender === 'user' ? 'white' : 'text.primary',
                position: 'relative',
              }}
            >
              <Typography variant="body1">
                {msg.text}
                {msg.includeLink && (
                  <Link
                    component="button"
                    onClick={() => setShowHipaaModal(true)}
                    sx={{ 
                      ml: 1,
                      color: 'primary.main',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontFamily: 'Courier New, Courier, monospace'
                    }}
                  >
                    here
                  </Link>
                )}
              </Typography>
              {msg.sender === 'assistant' && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                  {msg.showEmailOption && (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        // Add email notification request
                        fetch('https://chat.orin.health/notify-when-complete', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.access_token}`,
                          }
                        }).then(() => {
                          addMessageWithDelay({
                            text: "I'll send you an email when your health history analysis is complete.",
                            sender: 'assistant'
                          }, setChatHistory);
                        }).catch(error => {
                          console.error('Error setting up notification:', error);
                          addMessageWithDelay({
                            text: "I apologize, but I couldn't set up the email notification. Please check back in a few minutes.",
                            sender: 'assistant'
                          }, setChatHistory);
                        });
                      }}
                      sx={{
                        fontFamily: 'Courier New, Courier, monospace',
                        textTransform: 'none'
                      }}
                    >
                      Notify me by email
                    </Button>
                  )}
                  <IconButton 
                    size="small" 
                    onClick={() => handleFeedback(index, 'up')}
                    color={msg.feedback === 'up' ? 'primary' : 'default'}
                  >
                    <ThumbUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    onClick={() => handleFeedback(index, 'down')}
                    color={msg.feedback === 'down' ? 'primary' : 'default'}
                  >
                    <ThumbDownIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Paper>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Paper>
      <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px' }}>
        <TextField
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          variant="outlined"
          size="small"
          disabled={isLoading}
        />
        <Button 
          type="submit" 
          variant="contained" 
          endIcon={<SendIcon />}
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send'}
        </Button>
        <IconButton
          color="primary"
          onClick={handleResetChat}
          disabled={isLoading}
          title="Reset Chat"
        >
          <RefreshIcon />
        </IconButton>
      </form>

      <HipaaAuthModal
        open={showHipaaModal}
        onClose={() => setShowHipaaModal(false)}
        onAuthorize={handleHipaaAuthorize}
      />
    </Box>
  );
};

export default OrinChat; 