import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { axiosInstance } from '../api/axiosConfig';

const GoogleLoginButton = ({ onLoginSuccess }) => {
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const response = await axiosInstance.post('/auth/google-login', {
        credential: credentialResponse.credential
      });
      
      if (response.data && response.data.access_token) {
        const userData = {
          id: response.data.user_id,
          email: response.data.email,
          access_token: response.data.access_token
        };
        onLoginSuccess(userData);
      }
    } catch (error) {
      console.error('Google login error:', error);
    }
  };

  return (
    <GoogleLogin
      onSuccess={handleGoogleSuccess}
      onError={() => console.log('Login Failed')}
      useOneTap
    />
  );
};

export default GoogleLoginButton; 