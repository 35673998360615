// frontend/src/App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import MyHealthHistory from './components/MyHealthHistory';
import OrinChat from './components/OrinChat';
import LandingPage from './components/LandingPage';
import UserPanel from './components/UserPanel';
import ReferenceDetail from './components/ReferenceDetail';
import useAuth from './hooks/useAuth';
import useWebSocket from './hooks/useWebSocket';
import { DemoProvider, useDemo } from './contexts/DemoContext';
import DemoAccountModal from './components/DemoAccountModal';
import GoodbyePage from './components/GoodbyePage';

const theme = createTheme({
  typography: {
    fontFamily: 'Courier New, Courier, monospace',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Courier New, Courier, monospace',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Courier New, Courier, monospace',
        },
      },
    },
  },
});

const ProtectedLayout = ({ children }) => {
  const { user } = useAuth();
  const { status } = useWebSocket(user?.id, null, user?.access_token);

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <UserPanel user={user} />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        {children}
      </Box>
    </Box>
  );
};

function App() {
  const { user, login, logout } = useAuth();
  const { isDemo, showDemoModal, handleCloseDemoModal, showDemo } = useDemo();

  React.useEffect(() => {
    if (user && isDemo) {
      showDemo();
    }
  }, [user, isDemo, showDemo]);

  // Placeholder component for recommendations
  const RecommendationsPlaceholder = () => (
    <Box sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h4" sx={{ mb: 3, fontFamily: 'Courier New, Courier, monospace' }}>
        Recommendations
      </Typography>
      <Typography sx={{ fontFamily: 'Courier New, Courier, monospace' }}>
        Coming soon! This feature is currently under development.
      </Typography>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route 
          path="/" 
          element={
            user ? (
              <Navigate to="/chat" replace />
            ) : (
              <LandingPage 
                onLogin={login} 
                onRegister={() => {/* Implement registration logic */}} 
              />
            )
          } 
        />
        <Route
          path="/goodbye"
          element={<GoodbyePage />}
        />
        <Route
          path="/chat"
          element={
            <ProtectedLayout>
              <OrinChat />
            </ProtectedLayout>
          }
        />
        <Route
          path="/my-health-history"
          element={
            <ProtectedLayout>
              <MyHealthHistory />
            </ProtectedLayout>
          }
        />
        <Route
          path="/recommendations"
          element={
            <ProtectedLayout>
              <RecommendationsPlaceholder />
            </ProtectedLayout>
          }
        />
        <Route
          path="/reference/:type/:id"
          element={
            <ProtectedLayout>
              <ReferenceDetail />
            </ProtectedLayout>
          }
        />
      </Routes>
      <DemoAccountModal 
        open={showDemoModal}
        onClose={handleCloseDemoModal}
      />
    </ThemeProvider>
  );
}

export default App;
