import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SignatureCanvas from 'react-signature-canvas';

const StyledButton = styled(Button)({
  fontFamily: 'Courier New, Courier, monospace',
  border: '2px solid #333',
  color: '#333',
  padding: '10px 30px',
  margin: '10px',
  '&:hover': {
    backgroundColor: '#333',
    color: '#fff',
    border: '2px solid #333',
  },
});

const ScrollableContent = styled(Box)({
  maxHeight: '400px',
  overflowY: 'auto',
  padding: '20px',
  marginBottom: '20px',
  '& p': {
    marginBottom: '16px',
    fontFamily: 'Courier New, Courier, monospace',
  },
  '& ul': {
    marginLeft: '20px',
    marginBottom: '16px',
  },
});

const SignatureBox = styled(Paper)({
  border: '2px solid #333',
  marginTop: '20px',
  width: '100%',
  height: '150px',
  backgroundColor: '#f8f8f8',
  position: 'relative',
  '& canvas': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100% !important',
    height: '100% !important'
  }
});

const HipaaAuthModal = ({ open, onClose, onAuthorize }) => {
  const [acknowledged, setAcknowledged] = useState(false);
  const [signed, setSigned] = useState(false);
  const signatureRef = useRef();

  const handleClear = () => {
    signatureRef.current.clear();
    setSigned(false);
  };

  const handleSubmit = () => {
    if (acknowledged && signed) {
      // Save signature as base64 image if needed
      const signatureData = signatureRef.current.toDataURL();
      onAuthorize(signatureData);
      onClose();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          fontFamily: 'Courier New, Courier, monospace',
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Authorization for Use and Disclosure of Protected Health Information
      </DialogTitle>
      <DialogContent>
        <ScrollableContent>
          <Typography variant="body1" paragraph>
            <strong>Purpose:</strong><br />
            This authorization allows the use and disclosure of my Protected Health Information (PHI) for any purpose, including treatment, payment, healthcare operations, research, marketing, and other permissible activities under federal or state law.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Authorization:</strong><br />
            I authorize Orin Health to release my PHI to any individual, organization, or entity as deemed necessary for the above purposes, including but not limited to:
          </Typography>
          <ul>
            <li>Healthcare providers, insurance companies, and billing agents.</li>
            <li>Research institutions and government agencies.</li>
            <li>Other third parties as required by law or at the discretion of the disclosing party.</li>
          </ul>

          <Typography variant="body1" paragraph>
            <strong>Scope of Disclosure:</strong><br />
            This authorization permits the release of:
          </Typography>
          <ul>
            <li>My entire medical record, including sensitive information such as mental health, substance use, genetic information, HIV/AIDS status, and reproductive health data.</li>
            <li>Billing and payment information.</li>
            <li>Any other information requested under applicable law.</li>
          </ul>

          <Typography variant="body1" paragraph>
            <strong>Duration:</strong><br />
            This authorization remains in effect until revoked in writing by me or until the maximum period allowed by law.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Right to Revoke:</strong><br />
            I understand that I may revoke this authorization at any time by submitting a written request to Orin Health. Revocation does not apply to disclosures made prior to the receipt of such a request.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>Acknowledgment:</strong><br />
            I acknowledge that I understand the terms of this authorization and that information disclosed may no longer be protected by HIPAA once shared with the recipient. I release Orin Health from any liability related to this authorization.
          </Typography>

          <FormControlLabel
            control={
              <Checkbox 
                checked={acknowledged}
                onChange={(e) => setAcknowledged(e.target.checked)}
              />
            }
            label="I acknowledge and agree to the terms above"
          />

          <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
            Please sign below:
          </Typography>
          <SignatureBox>
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{
                className: 'signature-canvas',
                style: {
                  width: '100%',
                  height: '100%',
                  cursor: 'crosshair'
                }
              }}
              backgroundColor="rgb(248, 248, 248)"
              onEnd={() => setSigned(true)}
            />
          </SignatureBox>
          <Button 
            onClick={handleClear}
            sx={{ mt: 1, fontFamily: 'Courier New, Courier, monospace' }}
          >
            Clear Signature
          </Button>
        </ScrollableContent>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
        <StyledButton onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton 
          onClick={handleSubmit}
          disabled={!acknowledged || !signed}
        >
          Submit Authorization
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default HipaaAuthModal; 