import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { axiosInstance } from '../api/axiosConfig';
import RegisterModal from './RegisterModal';
import GoogleLoginButton from './GoogleLoginButton';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const StyledButton = styled(Button)({
  fontFamily: 'Courier New, Courier, monospace',
  border: '2px solid #333',
  color: '#333',
  padding: '10px 30px',
  margin: '10px',
  '&:hover': {
    backgroundColor: '#333',
    color: '#fff',
    border: '2px solid #333',
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontFamily: 'Courier New, Courier, monospace',
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'Courier New, Courier, monospace',
  },
  marginBottom: '1rem',
});

const TypingText = ({ text, onComplete }) => {
  const [displayText, setDisplayText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  // Store words in a ref to prevent re-splitting on each render
  const wordsRef = React.useRef(text.split(' '));

  useEffect(() => {
    const words = wordsRef.current;
    let isCurrentlyTyping = true;

    const typeWord = async () => {
      if (wordIndex < words.length && isCurrentlyTyping) {
        // Build the complete text up to the current word
        const currentText = words.slice(0, wordIndex + 1).join(' ');
        
        // More natural typing delays based on word type
        const currentWord = words[wordIndex];
        const commonWords = new Set(['I', 'am', 'a', 'to', 'and', 'the', 'of', 'in', 'your']);
        const keyTerms = new Set(['medical', 'assistant', 'health', 'wellness', 'understand', 'designed']);
        
        let baseDelay;
        if (commonWords.has(currentWord)) {
          baseDelay = Math.random() * 10 + 20; // 100-300ms for common words
        } else if (keyTerms.has(currentWord)) {
          baseDelay = Math.random() * 200 + 100; // 700-1500ms for key terms
        } else {
          baseDelay = Math.random() * 100 + 50; // 300-700ms for other words
        }

        // Add "thinking" pauses
        const hasPunctuation = /[.,!?]$/.test(currentWord);
        const isEndOfClause = hasPunctuation || wordIndex === words.length - 1;
        const pauseTime = isEndOfClause ? 500 : 
                         keyTerms.has(currentWord) ? 300 :
                         100;

        await new Promise(resolve => setTimeout(resolve, baseDelay));
        if (isCurrentlyTyping) {
          setDisplayText(currentText);
        }
        
        await new Promise(resolve => setTimeout(resolve, pauseTime));
        if (isCurrentlyTyping) {
          setWordIndex(prev => prev + 1);
        }
      } else if (onComplete && isCurrentlyTyping) {
        // Add final pause before showing buttons
        await new Promise(resolve => setTimeout(resolve, 1000));
        onComplete();
      }
    };

    typeWord();

    // Cleanup function to prevent state updates if component unmounts
    return () => {
      isCurrentlyTyping = false;
    };
  }, [wordIndex, onComplete]);

  return (
    <Typography
      variant="h5"
      component="div"
      sx={{
        fontFamily: 'Courier New, Courier, monospace',
        whiteSpace: 'pre-wrap',
        minHeight: '3em',
        fontWeight: 'bold',
        '&::after': {
          content: '"|"',
          animation: 'blink 1s step-end infinite',
          fontWeight: 'normal',
        },
        '@keyframes blink': {
          'from, to': { opacity: 1 },
          '50%': { opacity: 0 },
        },
      }}
    >
      {displayText}
    </Typography>
  );
};

const LoginModal = ({ open, onClose, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const formData = new URLSearchParams();
      formData.append('username', email);
      formData.append('password', password);
      formData.append('grant_type', 'password');

      const response = await axiosInstance.post('/auth/login', 
        formData.toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      
      if (response.data && response.data.access_token) {
        const userData = {
          id: response.data.user_id,
          email: email,
          access_token: response.data.access_token
        };
        onLoginSuccess(userData);
        onClose();
      } else {
        setError('Invalid login response. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response?.status === 422) {
        // Handle FastAPI validation errors
        const validationError = err.response?.data?.detail?.[0]?.msg;
        setError(validationError || 'Invalid login format. Please check your entries.');
      } else {
        setError(err.response?.data?.detail || 'Login failed. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          fontFamily: 'Courier New, Courier, monospace',
          minWidth: '400px',
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Login
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 2 }}>
          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          <GoogleLoginButton onLoginSuccess={onLoginSuccess} />
          <Box sx={{ my: 2, textAlign: 'center' }}>
            <Typography variant="body2">- OR -</Typography>
          </Box>
          <StyledTextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <StyledTextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
        <StyledButton onClick={onClose} disabled={isLoading}>
          Cancel
        </StyledButton>
        <StyledButton 
          onClick={handleLogin}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : 'Login'}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

const LandingPage = ({ onLogin }) => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const welcomeText = "Welcome. I am a medical assistant here to help you.";

  // If user is already set in state on mount, skip the landing screen
  useEffect(() => {
    if (user) {
      navigate('/chat', { replace: true });
    }
  }, [user, navigate]);

  const handleRegisterSuccess = async (userData) => {
    try {
      // This updates the user context and localStorage
      await login(userData);
      // Because “user” will update soon, we’ll navigate in the effect above
      // Or you can do an immediate:
      navigate('/chat', { replace: true });
    } catch (err) {
      console.error('Error during login after registration:', err);
    }
  };

  const handleLoginSuccess = (userData) => {
    onLogin(userData); // or directly call login(userData)
    navigate('/chat', { replace: true });
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: 4,
        }}
      >
        <TypingText 
          text={welcomeText} 
          onComplete={() => setShowButtons(true)} 
        />
        
        {showButtons && (
          <Box
            sx={{
              opacity: 1,
              transition: 'opacity 0.5s ease-in',
              mt: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box>
              <StyledButton
                variant="outlined"
                onClick={() => setShowLoginModal(true)}
                className="auth-button"
              >
                Login
              </StyledButton>
              <StyledButton
                variant="outlined"
                onClick={() => setShowRegisterModal(true)}
                className="auth-button"
              >
                Register
              </StyledButton>
            </Box>
          </Box>
        )}

        <LoginModal
          open={showLoginModal}
          onClose={() => setShowLoginModal(false)}
          onLoginSuccess={handleLoginSuccess}
        />

        <RegisterModal
          open={showRegisterModal}
          onClose={() => setShowRegisterModal(false)}
          onRegisterSuccess={handleRegisterSuccess}
        />
      </Box>
    </Container>
  );
};

export default LandingPage; 