import { useState } from 'react';
import { axiosInstance } from '../api/axiosConfig';

const useAuth = () => {
  const [user, setUser] = useState(() => {
    const userId = localStorage.getItem('userId');
    const userEmail = localStorage.getItem('userEmail');
    const userDataToken = localStorage.getItem('userDataToken');
    const token = localStorage.getItem('token');

    if (userId && userEmail && token && token !== 'null') {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return { 
        id: Number(userId), 
        email: userEmail, 
        user_data_token: userDataToken, 
        access_token: token 
      };
    }
    return null;
  });

  const login = async (userData) => {
    if (!userData || !userData.access_token) {
      throw new Error('Invalid login data received');
    }

    console.log('Logging in user:', userData);
    
    try {
      // Set the authorization header
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userData.access_token}`;
      
      // Test the token with a request to /users/me
      await axiosInstance.get('/users/me');
      
      // If validation succeeds, set the user
      setUser(userData);
      localStorage.setItem('userId', userData.id);
      localStorage.setItem('userEmail', userData.email);
      localStorage.setItem('token', userData.access_token);
      if (userData.user_data_token) {
        localStorage.setItem('userDataToken', userData.user_data_token);
      }
    } catch (error) {
      console.error('Login validation failed:', error);
      // Clean up any partial state
      logout();
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userDataToken');
    localStorage.removeItem('token');
    delete axiosInstance.defaults.headers.common['Authorization'];
  };

  return { user, login, logout };
};

export default useAuth; 